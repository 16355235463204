import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './store';
import Preview from './controllers/Preview';

export default function AppRoutes() {
    useEffect(() => {
        document.getElementById('core-loader').style.display = 'none';
    });

    return (
        <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/:type/:campaignId/:token" component={Preview} />
                </Switch>
            </Router>
        </div>
    );
}
