import { StyledEngineProvider } from '@mui/material/styles';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import getTheme from './styles/globals/theme';
import settings from './config/settings';
import PreviewRoutes from './preview-routes.js';
import './styles/setup/main.scss';

class App extends React.Component {
    componentDidMount() {}

    render() {
        document.title = settings.headerTitle;
        return (
            <div>
                <StyledEngineProvider injectFirst>
                    <CssVarsProvider theme={getTheme()}>
                        <SnackbarProvider maxSnack={3}>
                            <PreviewRoutes />
                        </SnackbarProvider>
                    </CssVarsProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}

export default App;
