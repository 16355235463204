import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store, { history } from './store.js';
import App from './preview-app.js';

__webpack_nonce__ = 'hvuMMV4HTWnV9EJkb4yJwXADNmVsRuvs'; // eslint-disable-line

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>
);
